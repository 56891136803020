import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useWikiStore = defineStore('wiki', () => {
    const parameters = ref({})

    return {
        parameters
    }
})
