import {onKeyStroke} from '@vueuse/core'
import {useWikiStore} from "../store/wiki"

export const useHelpPage = () => {
    const route = useRoute()
    const routeQuery = computed(() => route.query)

    const wikiStore = useWikiStore()

    const isHelpPage = computed(() => route.meta?.isHelpPage ?? false)
    const isWikiPage = computed(() => route.path?.startsWith('/wiki'))

    const parameters = ref({})
    const wikiParams = computed(() => wikiStore.parameters)
    const wikiParamsForUrl = computed(() => getWikiParamsForUrl(wikiParams.value))

    watch([parameters, routeQuery], () => wikiStore.parameters = {...routeQuery.value || {}, ...parameters.value || {}})

    const helpRoute = computed(() => {
        let helpRoute = ''
        if (isWikiPage.value) helpRoute = window.location.pathname.replace('/wiki', '')
        else helpRoute = isHelpPage.value === true ? route.name.replaceAll('-id', '').replaceAll('-', '/').trim() : isHelpPage.value
        if (helpRoute.startsWith('/')) helpRoute = helpRoute.substring(1)
        return helpRoute
    })

    const wikiRoute = computed(() => '/wiki' + (helpRoute.value?.startsWith('/') ? '' : '/') + helpRoute.value)

    function getWikiParamsForUrl(wikiParams: Object) {
        const searchParams = new URLSearchParams('')
        Object.keys(wikiParams).filter(param => !!wikiParams[param]).forEach(param => searchParams.append(param, wikiParams[param]))
        return searchParams.toString()
    }

    const listenForHelp = () => onKeyStroke('F1', (e) => {
        if (!isHelpPage.value) return
        e.preventDefault()
        const wikiParamsForUrl = getWikiParamsForUrl(useWikiStore().parameters)
        window.open(wikiRoute.value + (wikiParamsForUrl ? '?' : '') + wikiParamsForUrl, '_blank')
    })

    return {
        isHelpPage, // if current page has a corresponding help page, as by the isHelpPage meta tag
        isWikiPage, // if page is within the wiki subpage
        helpRoute, // the route identifier for the current help page, e.g. 'users/userBaseData'
        wikiRoute, // the route to the corresponding wiki page, e.g. '/wiki/users/userBaseData'
        wikiParams, // the parameters for the wiki page, e.g. {userRole: 'admin'}
        wikiParamsForUrl, // the parameters for the wiki page as a URL string, e.g. 'userRole=admin'
        listenForHelp, // listens for F1 key press and opens the corresponding wiki page
        setParams: (params: Object) => parameters.value = {...parameters.value, ...params}
    }
}